<template>
  <div>
    <v-container fluid style="text-align: center;">
      <v-row justify="start">
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="pesquisaTabela"
            append-icon="search"
            @input="filtrar"
            :label="`${$tc('label.pesquisar', 1)}`"
            hide-details
            clearable
          ></v-text-field>
        </v-col>
        <v-col class="text-center" cols="12" sm="4" v-if="listaLotes.length">
          <v-btn
            color="primary darken-1"
            class="mt-3"
            @click.native="openReprocessarGeralDialog">
            {{ $tc('label.reprocessar_todos')}}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-container fluid grid-list-md class="Container_Tabela py-3">
          <v-row align="center" justify="center" text-xs-center>
            <v-expansion-panels
              accordion
              focusable
              v-model="painel"
              v-if="!iniciandoPainel">
              <v-expansion-panel
                v-for="(lote, index) in listaLotes"
                :key="index">
                <v-expansion-panel-header>
                  <v-row no-gutters>
                    <h3 class="my-auto">{{ lote.lote }} :: {{ lote.qtdErros }} {{ lote.qtdErros === 1 ? $tc('label.inconsistencia', 1) : $tc('label.inconsistencia', 2)}}</h3>
                    <v-spacer/>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn icon
                          v-on="on"
                          @click.stop="openReprocessarDialog(lote)">
                          <v-icon color="green">cached</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('label.reprocessar_lote') }}</span>
                    </v-tooltip>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content ripple>
                  <notas-lista-inconsistencia-detalhado
                    ref="tabelaDetalhado"
                    :lote="lote"
                    :tipoErro="tipoErro"
                    :pesquisaTabela="pesquisaTabela"
                    @TotalInconsistenciasPorLote="atualizarLote"
                    @InconsistenciaReprocessada="atualizarTabela"/>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <span class="mb-3" v-if="!listaLotes.length">{{$t('label.tabela_sem_conteudo')}}</span>
          </v-row>
          <v-row class="text-xs-center mt-3" v-if="totalPagina > 1">
            <v-pagination
              v-model="pagina"
              :length="totalPagina"
              :total-visible="$vuetify.breakpoint.mdAndUp ? 15 : 7"
              @input="buscar()"
            ></v-pagination>
          </v-row>
        </v-container>
      </v-row>
    </v-container>
    <confirm
      ref="reprocessarDialog"
      :message="dialog.content"
      :persistent="true"
      @agree="reprocessarLote()">
    </confirm>
    <confirm
      ref="reprocessarDialogGeral"
      :message="$t('message.lote_reprocessar_possiveis')"
      :persistent="true"
      @agree="reprocessarGeral()">
    </confirm>
  </div>
</template>

<script>
import {
  getMoney,
} from '@/produto/common/functions/helpers';
import Confirm from '@/produto/shared-components/vuetify/dialog/Confirm';
import notasListaInconsistenciaDetalhado from './NotasNivel2DadosInconsistenciaDetalhado';

export default {
  name: 'NotasListaInconsistenciaAgrupado',
  components: {
    notasListaInconsistenciaDetalhado,
    Confirm,
  },
  props: {
    visao: String,
  },
  data() {
    return {
      resourcesInconsistencia: this.$api.notasInconsistencia(this.$resource),
      resourcesReprocessamento: this.$api.notasReprocessamento(this.$resource),
      pesquisaTabela: null,
      listaLotes: [],
      esperarDigitar: false,
      loteSelecionado: {},
      pagina: 1,
      totalPagina: 1,
      quantidadePagina: 5,
      painel: null,
      iniciandoPainel: true,
      dialog: {
        from: '',
        title: this.$t('label.atencao'),
        content: '',
        action() {},
      },
    };
  },
  watch: {
    visao: {
      handler() {
        this.buscar();
      },
    },
  },
  computed: {
    isSellIn() {
      return this.visao === 'SELLIN';
    },

    isDevolucao() {
      return this.visao === 'DEVOLUCAO';
    },

    isSellOut() {
      return this.visao === 'SELLOUT';
    },
  },
  methods: {
    getMoney,

    filtrar() {
      if (this.esperarDigitar) return;
      this.esperarDigitar = true;
      setTimeout(() => {
        this.esperarDigitar = false;
        this.buscar();
      }, 1000);
    },

    buscar() {
      const params = {
        filtro: this.pesquisaTabela,
        tipoErro: this.tipoErro,
        numeroPagina: this.pagina,
        tamanhoPagina: this.quantidadePagina,
      };

      this.resourcesInconsistencia.listarNotasInconsistenciaAgrupado(params).then((res) => {
        this.listaLotes = res.data.resposta;
        this.totalPagina = Math.ceil(res.data.quantidadeRegistrosPagina / this.quantidadePagina);
        this.iniciandoPainel = false;

        this.panelKey += 1;
      }, (err) => {
        this.$error(this, err);
      });
    },

    reprocessarGeral() {
      this.resourcesReprocessamento.reprocessarGeral({ tipo: this.tipoErro })
        .then(() => {
          this.$toast(this.$t('message.lote_reprocessado_possiveis'));
          this.filtrar();
          this.atualizarTabelasDetalhado();
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },

    reprocessarLote() {
      const lote = this.loteSelecionado;

      const params = {
        tipoErro: lote.tipo,
        numeroLote: lote.lote,
      };

      this.resourcesReprocessamento.reprocessarInconsistencia(params).then((response) => {
        this.atualizarTabela(response);
        this.atualizarTabelasDetalhado();
      })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },

    atualizarLote(lote, qtdErros) {
      const original = this.listaLotes.filter((l) => l === lote)[0];
      if (original) {
        original.qtdErros = qtdErros;
        this.$forceUpdate();
      }
    },

    atualizarTabela(msgRetorno) {
      this.filtrar();
      setTimeout(() => {
        if (msgRetorno.bodyText === 'Sucesso') {
          this.$toast(this.$t('message.inconsistencia_integrada_sucesso'));
        } else {
          this.$toast(this.$t('message.inconsistencia_continua_pendente'));
        }
      }, 3E2);
    },

    openReprocessarDialog(ref, from) {
      this.loteSelecionado = ref;
      this.dialog.content = this.$t('message.lote_reprocessar');
      this.dialog.action = this.reprocessarLote;
      this.dialog.from = from;
      setTimeout(() => this.$refs.reprocessarDialog.open());
    },

    openReprocessarGeralDialog() {
      this.$refs.reprocessarDialogGeral.open();
    },

    pararEsperar() {
      setTimeout(() => {
        this.esperarDigitar = false;
      }, 2E2);
    },

    atualizarTabelasDetalhado() {
      if (this.$refs.tabelaDetalhado) {
        this.$refs.tabelaDetalhado.forEach((tabela) => {
          tabela.buscar();
        });
      }
    },

  },
  beforeMount() {
  },
  mounted() {
    if (this.visao != null && this.visao.length) {
      if (this.isSellIn) {
        this.tipoErro = 'SELLIN';
      } else if (this.isDevolucao) {
        this.tipoErro = 'DEVOLUCAO';
      } else if (this.isSellOut) {
        this.tipoErro = 'SELLOUT';
      }
      this.buscar();
    }
  },
};
</script>

<style>
.Container_Tabela table.v-table tbody td:first-child,.Container_Tabela  table.v-table tbody td:not(:first-child),
  .Container_Tabela table.v-table tbody th:first-child, .Container_Tabela table.v-table tbody th:not(:first-child),
  .Container_Tabela table.v-table thead td:first-child, .Container_Tabela table.v-table thead td:not(:first-child),
  .Container_Tabela table.v-table thead th:first-child, .Container_Tabela table.v-table thead th:not(:first-child) {
  padding: 0 11px;
}

.Barra_Navegacao .v-item-group.v-bottom-nav .v-btn,.Barra_Navegacao .v-item-group.v-bottom-nav .v-btn--active {
  padding: 4px;
}
</style>
